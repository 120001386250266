import React from 'react'
import { graphql, Link } from 'gatsby'
// import moment from 'moment'
import theme from '../helpers/theme'
import Layout from '../components/layout'
// import EventThumbnail from '../components/event-thumbnail'
import FeatureThumbnail from '../components/feature-thumbnail'
import RichText from '../components/richtext'
// import Markdown from '../components/markdown'
// import LinkOut from '../components/link-out'
// import { One, Two, Three } from '../components/numerals'
import SEO from '../components/seo'
import { PopUpTimer } from '../components/popup'

const BookTemplate = (props) => {

  const settings = props.data.settings.edges[0].node
  const features = props.data.features.edges

  // const now = parseInt(moment().format('YYYYMMDD'))

  // BOOK CONTENT
  const {
    title,
    // mainHeading,
    // mainDescription,
    // buyLink,
    // buyLinkText,
    // price,
    // relatedFeatures,
    // buyLinks
  } = settings.featuredBook

  // MICROCOPY
  const {
    // events,
    viewAll,
    features: featuresTitle,
    readFeature,
  } = settings

  return (
    <Layout image={settings.navImageHome} theme={theme.red} size="big" settings={settings} location={props.location} showSplash>

      <div className="introduction fs flex-center guttersx2">
        <div className="line top"></div>
        <div className="pad w100">
          <RichText json={settings.homepageQuote.json} />
        </div>

        <div className="line bottom"></div>
      </div>

      <section className="">
        <div className="guttersx2 innerx2 border-bottom">
          <h1 className="section-heading">
            <div className="">
							{featuresTitle}
            </div>
          </h1>
        </div>

        <div className="masonry">
          {features.map(({ node }, i) => (
            <FeatureThumbnail className="feature-thumbnail column-cell" {...node} theme={theme.green} linkLabel={readFeature} key={`${node.slug}-feature-link`} />
          ))}
        </div>

        <div className="guttersx2 inner">
          <Link to="/features" className="h2 link">{viewAll}</Link>
        </div>
      </section>

			<PopUpTimer copy={settings.newsletterForm} />
    </Layout>
  )
}

export default BookTemplate

export const featureLinkFragment = graphql`
  fragment FeatureLink on ContentfulFeature {
    node_locale
    title
    slug
    coverImage {
      fluid(maxWidth: 600, quality: 90) {
        srcSet,
        sizes,
        src,
        aspectRatio
      }
    }
    # thumbnailCopy {
    #   childMarkdownRemark {
    #     html
    #   }
    # }
  }
`

export const settingsFragment = graphql`
  fragment Settings on ContentfulSettings {
    homepageQuote {
      json
    }
    splashImage {
      fluid(maxWidth: 1600, quality: 90) {
        srcSet,
        sizes,
        src,
        aspectRatio
      }
    }
    splashVideo {
      file {
        url
      }
    }
    featuredBook {
      title
      mainHeading
      mainDescription {
        childMarkdownRemark {
          html
        }
      }
      whereToBuy {
        childMarkdownRemark {
          html
        }
      }
      buyLinks {
        title
        label
        url
      }
      price
      buyLink
      buyLinkText
      relatedFeatures {
        ...FeatureLink
      }
    }
    navImageHome {
      fluid {
        src
        srcSet
      }
    }
    navImageAbout {
      fluid {
        src
        srcSet
      }
    }
    navImageEvents {
      fluid {
        src
        srcSet
      }
    }
    navImageFeatures {
      fluid {
        src
        srcSet
      }
    }
    showLanguages
    ## MICROCOPY ##
    events
    upcomingEvents
    pastEvents
    viewAll
    features
    readFeature
    about
    press
    contact
    stockists
		menuFeatures
		menuEvents
    ## END ##
		newsletterForm {
			...FormCopyFragment
		}
  }
`

export const eventFragment = graphql`
  fragment EventLink on ContentfulEvent {
    node_locale
    title
    slug
    start
    startDay: start(formatString: "D")
    startMonth: start(formatString: "MMM")
    end
    endDay: end(formatString: "D")
    endMonth: end(formatString: "MMM")
    dateMonth: start(formatString: "D MMM")
    year: start(formatString: "YYYY")
    details {
      childMarkdownRemark {
        html
      }
    }
    coverImage {
      fluid(maxWidth: 200, quality: 90) {
        srcSet,
        sizes,
        src,
        aspectRatio
      }
    }
  }
`
