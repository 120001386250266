import React, { useState, useEffect } from 'react'
import { graphql } from 'gatsby'

function useInput(initialValue) {
	const [value, setValue] = useState(initialValue || "");

	const handleChange = (e) => {
		setValue(e.target.value)
	}

	return [value, handleChange];
}

const Input = (props) => {

	const [value, handleChange] = useInput("")

	return (
		<input value={value} onChange={handleChange} {...props} />
	)
}

const PopUp = ({ showPopup, setShowPopup, copy = {} }) => {

	const {
		title = 'A CALL TO REFLECTION test',
		timeToAct = 'Time to act',
		intro = 'Ignite the changemaker in you. Listen to Dax Dasilva’s FREE Age of Union Audiobook.',
		firstName = 'FIRST NAME',
		lastName = 'LAST NAME',
		email = 'EMAIL',
		instagram = 'INSTAGRAM HANDLE',
		consent = 'By submitting this form, you agree to receive email marketing from Age Of Union',
		button = 'LISTEN TO THE AUDIOBOOK'
	} = copy

  const handleClose = () => {
    setShowPopup(false)
  }

  return(
    <div className={`popup pfix fs z-6 ${showPopup ? "" : "hide"}`}>
      <div id="mailchimp-form" className="padx2 mailchimp-form">
        <div className="pabs r0 gutters" style={{ zIndex: 4 }}>
          <span className="fsx4 pointer" onClick={handleClose}>&times;</span>
        </div>
        <div id="mc_embed_signup">
					<h1 className="hide-on-mobile align-center outerx2">{title || 'A CALL TO REFLECTION'}</h1>
					<p className="hide-on-mobile align-center outerx2"><strong>{timeToAct || 'Time to act'}</strong></p>
          <p>
            <strong>{intro || 'Ignite the changemaker in you. Listen to Dax Dasilva’s FREE Age of Union Audiobook.'}</strong>
          </p>
          <form action="//neverapart.us11.list-manage.com/subscribe/post?u=ac5f1210ea6965f32460c367e&amp;id=594b5738e9" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" className="validate" target="_blank" noValidate>
            <div className="seven columns outerx2">
              <div id="mc_embed_signup_scroll">
                <div className="form-item mc-field-group required flex-center">
									<Input type="text" name="FNAME" placeholder={firstName || "FIRST NAME"} required />
                  <span>*</span>
                </div>
                <div className="form-item mc-field-group required flex-center">
									<Input type="text" name="LNAME" placeholder={lastName || 'LAST NAME'} required />
                  <span>*</span>
                </div>
                <div className="form-item mc-field-group required flex-center">
									<Input type="email" name="EMAIL" placeholder={email || 'EMAIL'} required />
                  <span>*</span>
                </div>
								{/* <div className="form-item mc-field-group required flex-center">
									<Input type="text" name="MMERGE5" placeholder={instagram || 'INSTAGRAM HANDLE'} />
                  <span style={{ opacity: 0 }}>*</span>
                </div> */}
                <div className="form-item checkbox-form-item required flex outerx2">
									<input type="checkbox" name="agree-checkbox" className="checkbox required" id="agree-checkbox" defaultValue="false" />
									<div className="checker"></div>
									<label htmlFor="agree-checkbox">{consent || `By submitting this form, you agree to receive email marketing from Age&nbsp;Of&nbsp;Union`}</label>
									<span>*</span>
                </div>
                <div id="mce-responses" className="clear">
                  <div className="response" id="mce-error-response" style={{ display: "none" }}></div>
                  <div className="response" id="mce-success-response" style={{ display: "none" }}></div>
                </div>
                <div style={{ position: "absolute", left: "-5000px" }} aria-hidden="true">
                  <input type="text" name="b_ac5f1210ea6965f32460c367e_a354600ecd" tabIndex="-1" value=""/>
                </div>
              </div>
            </div>
            <div className="five columns">
              <input type="submit" value={button || 'Listen to the Audiobook'} name="subscribe" id="mc-embedded-subscribe" className="main-button small" />
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export const PopUpTimer = ({ copy }) => {

	const [show, set] = useState(false)

	useEffect(() => {
		const timer = setTimeout(() => {
			set(true)
		}, 6000)
		return () => {
			clearTimeout(timer)
		}
	}, [])

	return (
		<PopUp showPopup={show} setShowPopup={set} copy={copy} />
	)
}

export const popupFragment = graphql`
  fragment FormCopyFragment on ContentfulFormCopy {
    title
		timeToAct
		intro
		firstName
		lastName
		email
		# instagram
		consent
		button
  }
`

export default PopUp


